@import "/src/styles/mixin";

.wrap {
  width: calc(100vw - 24px);
  max-width: 329px;

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    max-width: 329px;
  }

  @media only screen and (min-width: 320px) and (max-width: 424px) {
    max-width: calc(100vw - 40px);
  }
  .order_id{
    @include font14Bold;
    margin-bottom: 24px;
  }
  .order_info{
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 24px;
    .status{
      @include font14Bold;
    }
    .order_response{
      @include font14;
    }
  }
}
.check{
  opacity: .7;
}
.response{
  display: none;
}
.input {
  padding: 16px 20px 8px;
  border: none;
  width: 400px;

  &Wrap {
    margin-bottom: 24px;
  }
}

.text {
  @include font14;
}

.button {
  width: 100%;
  padding: 14px 26px;
}