@import "/src/styles/mixin";

.modal_container {
  @media screen and (max-width: 750px) {
    padding: 0 0;
  }
}

.wrap {
  width: 100%;
  width: 457px;
  max-width: 457px;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: calc(100vw - 80px);
  }

  @media screen and (min-width: 320px) and (max-width: 425px) {
    width: calc(100vw - 50px);
  }

  .text {
    @include font14;
    color: var(--grey-third);
    margin-bottom: 4px;
  }

  .defaultCitiesList_scroll {
    overflow-y: auto;
    flex-direction: column;
    flex-wrap: nowrap;

    button {
      margin-bottom: 12px !important;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 9px 20px;
    margin-bottom: 12px;
    background-color: var(--blue-background-color);
    border-radius: var(--border-radius-small);

    &Text {
      @include font14;
      color: var(--grey-third);
      margin-bottom: 4px;

      @media screen and (max-width: 456px) {
        span {
          display: none;
        }
      }
    }
  }
}

.defaultCities {
  &List {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 194px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow-x: hidden;

    width: auto;
  }

  &Button {
    @include font14;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:nth-child(6n) {
      margin-bottom: 0;
    }

    .empty {
      width: 17px;
      height: 100%;
    }

    .region {
      color: var(--grey-color);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    &List {
      height: 210px;
      width: 100%;
      overflow: scroll;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }

    &Button:nth-child(6n) {
      margin-bottom: 10px;
    }
  }
}


.input {
  padding: 16px 20px 8px;
  border: none;

  &Wrap {
    margin-bottom: 24px;
  }
}