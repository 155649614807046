// @mixin clearStyles {
//   margin: 0;
//   padding: 0;
// }

// @mixin titleH1 {
//   @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
//     margin-bottom: 30px;
//   }

//   @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
//     margin-bottom: 20px;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     margin-bottom: 16px;
//   }
// }

// @mixin font35 {
//   font-size: 35px;
//   font-weight: 800;
//   line-height: 45px;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 30px;
//     line-height: 40px;
//   }
// }

// @mixin font30 {
//   font-size: 30px;
//   font-weight: 800;
//   line-height: 40px;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 20px;
//     line-height: 34px;
//   }
// }

// @mixin font24 {
//   font-size: 24px;
//   font-weight: 800;
//   line-height: 35px;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 20px;
//     line-height: 32px;
//   }
// }

// @mixin font24Bold {
//   font-size: 24px;
//   font-weight: 900;
//   line-height: 40px;
//   letter-spacing: 0.24px;

//   @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
//     font-size: 20px;
//     font-weight: 800;
//     line-height: 32px;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 767.98px) {
//     font-size: 16px;
//     font-weight: 800;
//     line-height: 32px;
//     letter-spacing: 0.16px;
//   }
// }

// @mixin font20 {
//   font-size: 20px;
//   font-weight: 800;
//   line-height: 32px;

//   @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
//     font-size: 18px;
//     font-weight: 800;
//     line-height: 28px;
//   }
// }

// @mixin font18 {
//   font-size: 18px;
//   font-weight: 800;
//   line-height: 28px;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 16px;
//     line-height: 32px;
//   }
// }

// @mixin font16 {
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 32px;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 14px;
//     line-height: 24px;
//   }
// }

// @mixin font16Bold {
//   @include font16;
//   font-weight: 800;

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     font-size: 14px;
//     line-height: 24px;
//   }
// }

// @mixin font14 {
//   font-size: 14px;
//   line-height: 24px;
// }

// @mixin font14Bold {
//   font-size: 14px;
//   line-height: 24px;
//   font-weight: 800;
// }

// @mixin font12 {
//   font-size: 12px;
//   font-weight: 800;
//   line-height: 20px;
// }

// @mixin font11 {
//   font-size: 11px;
//   font-weight: 300;
// }

// @mixin contentButton {
//   background-color: var(--primary-color);
//   border-radius: var(--border-radius-small);
//   color: var(--black-color);
//   cursor: pointer;
//   transition: background 0.2s ease;
//   border: 1px solid var(--primary-color);

//   &:hover {
//     border: 1px solid var(--hover-primary-color);
//     background-color: var(--hover-primary-color);
//   }

//   &:active {
//     border: 1px solid var(--hover-primary-color);
//     background-color: var(--primary-color);
//   }

//   &:disabled {
//     border: 1px solid var(--grey-lines-color);
//     background-color: transparent;
//     cursor: default;
//   }
// }

// @mixin contentButtonTransparent {
//   border-radius: var(--border-radius-small);
//   color: var(--black-color);
//   cursor: pointer;
//   border: 1px solid var(--secondary-color);
//   transition: border 0.2s ease;

//   &:hover {
//     border: 1px solid var(--hover-primary-color);
//   }

//   &:disabled {
//     border: 1px solid var(--grey-lines-color);
//     background-color: transparent;
//     cursor: default;
//   }
// }

// @mixin lineHover {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 0;
//     height: 1px;
//     background-color: var(--black-color);
//     transition: width 0.2s ease;
//   }

//   &:hover {
//     &::before {
//       width: 100%;
//     }
//   }
// }

// @mixin dashedBottomLine {
//   position: relative;

//   &::before {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 1px;
//     border-bottom: 1px dashed var(--grey-color);
//   }
// }

// @mixin contentInput {
//   @include font14;
//   border: 1px solid var(--grey-lines-color);
//   border-radius: var(--border-radius-small);
//   transition: border-color 0.2s ease;

//   &:focus,
//   &:hover {
//     border-color: var(--black-color);
//   }

//   &::placeholder {
//     color: var(--grey-color);
//   }

//   &.is-not-valid {
//     border-color: var(--red-color);
//     animation: 0.1s tremor ease-out 1;
//   }
// }

// @mixin arrowSwiper {

//   &-prev,
//   &-next {
//     top: 50%;
//     width: 48px;
//     height: 48px;
//     border-radius: 100%;
//     background-color: var(--blue-background-color);
//     box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

//     &::after {
//       font-size: 14px;
//       font-weight: 900;
//       color: var(--black-color);
//     }

//     &.swiper-button-disabled {
//       display: none;
//     }
//   }

//   &-prev {
//     left: 5px;
//   }

//   &-next {
//     right: 5px;
//   }

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     &-prev,
//     &-next {
//       display: none;
//     }
//   }
// }

// @mixin swiperPagination {
//   display: none;

//   &-bullet {
//     border-radius: 2px;
//     height: 4px;
//     width: 4px;
//     transition: width 0.2s ease;
//   }

//   &-bullet-active {
//     background: var(--grey-color);
//   }

//   @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
//     display: block;
//     bottom: 0 !important;
//   }
// }
@mixin clearStyles {
  margin: 0;
  padding: 0;
}

@mixin titleH1 {
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 16px;
  }
}

@mixin font35 {
  font-size: 35px;
  font-weight: 800;
  line-height: 45px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 30px;
    line-height: 40px;
  }
}

@mixin font30 {
  font-size: 30px;
  font-weight: 800;
  line-height: 40px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 20px;
    line-height: 34px;
  }
}

@mixin font24 {
  font-size: 24px;
  font-weight: 800;
  line-height: 35px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 20px;
    line-height: 32px;
  }
}

@mixin font24Bold {
  font-size: 24px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.24px;

  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    font-size: 20px;
    font-weight: 800;
    line-height: 32px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    font-size: 16px;
    font-weight: 800;
    line-height: 32px;
    letter-spacing: 0.16px;
  }
}

@mixin font20 {
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;

  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    font-size: 18px;
    font-weight: 800;
    line-height: 28px;
  }
}

@mixin font18 {
  font-size: 18px;
  font-weight: 800;
  line-height: 28px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 16px;
    line-height: 32px;
  }
}

@mixin font16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 14px;
    line-height: 24px;
  }
}

@mixin font16Bold {
  @include font16;
  font-weight: 800;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    font-size: 14px;
    line-height: 24px;
  }
}

@mixin font14 {
  font-size: 14px;
  line-height: 24px;
}

@mixin font14Bold {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
}

@mixin font13 {
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
}

@mixin font12 {
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
}

@mixin font11 {
  font-size: 11px;
  font-weight: 300;
}

@mixin contentButton {
  background-color: var(--primary-color);
  border-radius: var(--border-radius-small);
  color: var(--black-color);
  cursor: pointer;
  transition: background 0.2s ease;
  border: 1px solid var(--primary-color);

  &:hover {
    border: 1px solid var(--hover-primary-color);
    background-color: var(--hover-primary-color);
  }

  &:active {
    border: 1px solid var(--hover-primary-color);
    background-color: var(--primary-color);
  }

  &:disabled {
    border: 1px solid var(--grey-lines-color);
    background-color: transparent;
    cursor: default;
  }
}

@mixin contentButtonTransparent {
  border-radius: var(--border-radius-small);
  color: var(--black-color);
  cursor: pointer;
  border: 1px solid var(--secondary-color);
  transition: border 0.2s ease;
  background-color: transparent;
  &:hover {
    border: 1px solid var(--hover-primary-color);
    background-color: transparent;
  }
  &:disabled {
    border: 1px solid var(--grey-lines-color);
    background-color: transparent;
    cursor: default;
  }
}

@mixin lineHover {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--black-color);
    transition: width 0.2s ease;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

@mixin solidBottomLine {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--secondary-color);
  }
}

@mixin dashedBottomLine {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed var(--grey-color);
  }
}

@mixin contentInput {
  @include font14;
  border: 1px solid var(--grey-lines-color);
  border-radius: var(--border-radius-small);
  transition: border-color 0.2s ease;

  &:focus,
  &:hover {
    border-color: var(--black-color);
  }

  &::placeholder {
    color: var(--grey-color);
  }

  &.is-not-valid {
    border-color: var(--red-color);
    animation: 0.1s tremor ease-out 1;
  }
}

@mixin arrowSwiper {
  &-prev,
  &-next {
    top: 50%;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: var(--blue-background-color);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);

    &::after {
      font-size: 14px;
      font-weight: 900;
      color: var(--black-color);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &-prev {
    left: 5px;
  }

  &-next {
    right: 5px;
  }

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    &-prev,
    &-next {
      display: none;
    }
  }
}

@mixin swiperPagination {
  display: none;

  &-bullet-active {
    background: var(--grey-color);
  }

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    display: block;
    bottom: 0 !important;
  }
}


@mixin customScroll {
  box-sizing: border-box;
  scroll-margin-top: 120px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;

    &-track {
      background: var(--grey-secondary);
    }

    &-thumb {
      border-radius: 100px;
      background-color: var(--secondary-color);
    }
  }
}