@import "/src/styles/mixin";

.new,
.sale,
.license,
.default,
.delivery,
.firstWeapon {
  @include font12;
  font-weight: 500;
  padding: 4px 8px;
  white-space: nowrap;
  text-align: center;
  border-radius: var(--border-radius-small);
  display: flex;
  align-items: center;
  gap: 4px;
  @media only screen and (max-width: 767.98px) {
    font-size: 9px;
    line-height: 16px;
  }
}
.new {
  background-color: var(--label-new-color);
}

.license {
  background-color: var(--label-license-color);
}

.sale {
  background-color: var(--label-sale-color);
}

.default {
  background-color: var(--label-new-color);
}

.delivery {
  // margin-top: auto;
  padding: 0px 8px;
  background-color: var(--primary-color);
  font-size: 11px;
}

.icon {
  width: 20px;
}

.firstWeapon {
  background-color: var(--green-secondary);
  color: var(--white-color);
}
