@import "/src/styles/mixin";
.wrap{
  width: 310px;
  @media screen and (min-width: 320px) and (max-width: 425px) {
    width: calc(100vw - 40px);
  }
}
.text {
  @include font14;
  margin-bottom: 12px;
}
.smallText {
  font-size: 11px;
}
